import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Container from 'react-bootstrap/Container';

import Layout from '../components/layout';
import Header from '../components/header';

const Product = (props) => {
  const {
    data: {
      markdownRemark: {
        frontmatter: { jyeManNo, category, tags, description, coverImgPath },
      },
    },
  } = props;
  return (
    <Layout>
      <Container>
        <Header title={`${category} > ${jyeManNo}`} />
        <Img fixed={coverImgPath.childImageSharp.fixed} />
        <div>{description}</div>
        <div>Tags: {tags}</div>
      </Container>
    </Layout>
  );
};

export const productQuery = graphql`
  query ProductQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        jyeManNo
        category
        tags
        description
        coverImgPath {
          childImageSharp {
            fixed(width: 180, height: 180) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`;

export default Product;
